module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#F1FAEE',

  text: '#333333',

  primary: '#457b9d',
  primaryLight: '#aac7da',
  primaryDark: '#2c4e63',

  secondary: '#8aced0',
  secondaryLight: '#d3edee',
  secondaryDark: '#409da0',
};
